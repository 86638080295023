import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { useEffect, useState } from 'react';
import { useStripe, useElements,Elements, CardElement } from '@stripe/react-stripe-js';
import { Col, Container, Row, Form, Button } from "react-bootstrap";
import { validateService} from "../service/validation.service";
import { apiService} from "../service/api.service";
// import { bankCodes,docType} from "../config/config"
import Loader from "../common/Loader";
import swal from 'sweetalert';
import { useTranslation, initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import '../i18n';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from "moment";
const BankDetails = () => {


  const { t } = useTranslation();

  const [loader,setLoader]=useState(false)
  const [updateDetail,setupdateDetail]=useState(false)
  const [updatechecked,setupdatechecked]=useState(true)
  const [updateShow,setupdateShow]=useState(false)


  const [bankCodes, setbankCodes] = useState([]);
  const [docType, setdocType] = useState([]);
  const [accType, setaccType] = useState([]);
  
  const [bankFieldsErrs, setbankFieldsErrs] = useState({
        accountNumber: "",
        account_numberShow:"",
        bankCode: "",
        documentNumber: "",
        documentType: "",
        expeditionDate:"",
        fullName:"",
        birthDate:"",
  
});
const stripe = useStripe();
const [bankFields, setbankFields] = useState({
      accountNumber: "",
      accountType:"C",
        bankCode: "",
        documentNumber: "",
        documentType: "",
        expeditionDate:"",
        fullName:"",
        birthDate:"",
});
useEffect(() => { 
 

     getBank()
     getBankData()
     
  
  
   },[])

const handleKeyDownnumber = (event) => {
  console.log("=====event====", event.which);
  if (event.which === 32) {
    event.preventDefault(); // Prevent space key action
  }
  if (event.key === "Enter" || event.which === 13) {
    event.preventDefault(); // Prevent the default behavior (page refresh)
    // Handle your logic here
  }
};

const handleSubmit = ()=>{
  const isValid = validateService.validateBankData(setbankFieldsErrs,bankFields)
  if (isValid) {
  setLoader(true)

  apiService.addPayuBank(bankFields).then((res) => {
    console.log(res.data)
    swal({
      title: t("success"),
      text: res.data.message,
      icon: "success",
      buttons: t("ok"),
    }).then((confirmed) => {

      window.location.reload()
    });
  //  swal(t("success"),res.data.message,"success")
  
    setLoader(false)
  }).catch((err) => { 
    setLoader(false)
 
    // setupdateShow(true)
  });
} else {
  console.log("Form is invalid. Please check errors:", bankFieldsErrs);
}
}

const getBank = () => {
  setLoader(true)
  let bankDetail={
    accountNumber: "",
      accountType:"C",
        bankCode: "",
        documentNumber: "",
        documentType: "",
        expeditionDate:"",
        fullName:"",
        birthDate:"",
  }
  apiService.getPayuBank().then((res) => {
    setLoader(false)
     console.log("======Get=bank data====>", res.data.data.resource )
     localStorage.setItem("stripeBankId",true)
     setupdateShow(false)
     let bankDatas =   res.data.data.resource
    bankDetail.account_numberShow=bankDatas.accountNumber
    bankDetail.accountNumber=bankDatas.accountNumber
    bankDetail.accountType=bankDatas.accountType
    bankDetail.bankCode=bankDatas.bankCode
    bankDetail.bankName=bankDatas.bankName
    bankDetail.documentNumber=bankDatas.documentNumber
    bankDetail.documentType=bankDatas.documentType
    bankDetail.expeditionDate=bankDatas.expeditionDate
    bankDetail.fullName=bankDatas.fullName
    bankDetail.birthDate=bankDatas.birthDate
   
    if(bankDetail.accountNumber){
       setupdateDetail(true)

    }
     console.log("====bankDetail==>>.",bankDetail)
     setbankFields(bankDetail)
      // setbankList(res.data.data)

  }).catch((err) => { 
    setLoader(false)
 
    // setupdateShow(true)
  });


}

const getBankData = () => {
  setLoader(true)

  apiService.getPayuBankData().then((res) => {
    setLoader(false)
     console.log("======Get=bank data====>", res.data.data)
     setbankCodes(res.data.data.banks)
     setdocType(res.data.data.identificationDocuments)
     setaccType(res.data.data.accountTypes
      )
     setupdateShow(false)
  }).catch((err) => { 
    setLoader(false)
 
    // setupdateShow(true)
  });


}

const varifyAccount = ()=>{

  apiService.stripLink().then((res) => {
    console.log(res.data.data.url)
    window.open(res.data.data.url, '_blank');
    setLoader(false)
  }).catch((err) => { 
    setLoader(false)
 
    // setupdateShow(true)
  });
}
const handleExpDateChange = date => {
  console.log("=====date===", date)
  date = new Date(date)
  console.log("=====date===", date)
  let dt = moment(date).format("YYYY-MM-DD")
  setbankFields((prevObj) => {  return { ...prevObj, expeditionDate: dt}; })
  console.log("=====dt===", dt)
  // setStartDate(dt);
}

const handleDobDateChange = date => {
  console.log("=====date===", date)
  date = new Date(date)
  console.log("=====date===", date)
  let dt = moment(date).format("YYYY-MM-DD")
  setbankFields((prevObj) => {  return { ...prevObj, birthDate: dt}; })
  console.log("=====dt===", dt)
  // setStartDate(dt);
}
  return (
    <>
        {loader && <Loader/>}
      <div className="height-footer contact-us-section">
        <Container>
          <div className="">
            <div className='product-header-area'>
              <div>
                <h2 className='subheading-under'>{t("bank_detail")}</h2>
                <p>{t("view_and_manage_bank")}</p>
              </div>
              {/* {updateShow&&
              <div className='under-heading'>
                <Button className='green-btn' onClick={()=>{updateDetail?setupdateDetail(false):setupdateDetail(true)}}>Update Details</Button>
              </div>} */}
            </div>
            <Row className='justify-content-center'>
              <Col md={6}>
                
                <div className='stock-shadow'>
                  <Form className="login-body mt-3">
                  <Form.Group className="mb-3 position-relative">
                      <Form.Label>{t("bank_code")} </Form.Label>
                       {updateDetail?
                       <Form.Control disabled={updateDetail}  maxLength={50} type="text"  value={bankFields?.bankName} />
                       :
                      <Form.Select aria-label="Default select example" value={bankFields.bankCode} onChange={(e) => setbankFields((prevObj) => {  return { ...prevObj, bankCode: e.target.value}; })}>
                                    <option value="">{t("select_bank")}</option>
                                    {bankCodes.map((code, i) => (
                                        <option key={i} value={code.key} >
                                            {code.label}
                                        </option>
                                    ))}
                                </Form.Select>}
                      {/* <Form.Control disabled={updateDetail}  maxLength={50} type="text" placeholder={t("bank_name_place")} value={bankFields?.bank_name} onChange={(e) => setbankFields((prevObj) => {  return { ...prevObj, bank_name: e.target.value}; })}/> */}
                      <span className="errors">{t(bankFieldsErrs.bankCode)}</span>
                    </Form.Group>
                    <Form.Group className="mb-3 position-relative">
                      <Form.Label>{t("fullName")} </Form.Label>
                      <Form.Control type="text" disabled={updateDetail} placeholder={t("acc_holder_name_place")}  value={bankFields?.fullName}  onChange={(e) => setbankFields((prevObj) => {  return { ...prevObj, fullName: e.target.value}; })}/>
                       <span className="errors">{t(bankFieldsErrs.fullName)}</span>
                    </Form.Group>
                    <Form.Group className="mb-3 position-relative">
                      <Form.Label>{t("acc_type")} </Form.Label>
                      <select disabled={updateDetail} className='form-select' value={bankFields?.accountType} onChange={(e) => setbankFields((prevObj) => {  return { ...prevObj, accountType: e.target.value}; })}>
                      {accType.map((code, i) => (
                        <option key={i} value={code.key} >
                        {code.label}
                    </option>
                ))}
                      </select>
                     <span className="errors">{t(bankFieldsErrs.accountType)}</span>
                    </Form.Group>
                  <Form.Group className="mb-3 position-relative">
                      <Form.Label>{t("acc_number")} </Form.Label>
                      <Form.Control    onWheel={() => document.activeElement.blur()} onKeyDown={handleKeyDownnumber} type={updateDetail?"text":"number"}  disabled={updateDetail}
                      placeholder={t("acc_number_place")} value={bankFields?.account_numberShow
                      } onChange={(e) => setbankFields((prevObj) => {  return { ...prevObj, accountNumber: e.target.value,account_numberShow:e.target.value}; })}/>
                      <span className="errors">{t(bankFieldsErrs.accountNumber)}</span>
                    </Form.Group>
                    <Form.Group className="mb-3 position-relative">
                      <Form.Label>{t("document_type")} </Form.Label>
                      <Form.Select disabled={updateDetail}  aria-label="Default select example" value={bankFields.documentType} onChange={(e) => setbankFields((prevObj) => {  return { ...prevObj, documentType: e.target.value}; })}>
                                    <option value="">{t("document_type")}</option>
                                    {docType.map((doc, i) => (
                                        <option key={i} value={doc.key} >
                                            {doc.label}
                                        </option>
                                    ))}
                                </Form.Select>
                      {/* <Form.Control disabled={updateDetail}  maxLength={50} type="text" placeholder={t("bank_name_place")} value={bankFields?.bank_name} onChange={(e) => setbankFields((prevObj) => {  return { ...prevObj, bank_name: e.target.value}; })}/> */}
                      <span className="errors">{t(bankFieldsErrs.documentType)}</span>
                    </Form.Group>
                 
                 
                  
                    <Form.Group className="mb-3 position-relative">
                      <Form.Label>{t("document_number")} </Form.Label>
                      <Form.Control disabled={updateDetail}  type="text"  maxLength={30}placeholder={t("document_number_place")}  value={bankFields?.documentNumber} onChange={(e) => setbankFields((prevObj) => {  return { ...prevObj, documentNumber: e.target.value}; })}/>
                      <span className="errors">{t(bankFieldsErrs.documentNumber)}</span>
                    </Form.Group>
                  



                 
                    <Form.Group className="mb-3 position-relative">
                      <Form.Label>{t("expedition_date")} </Form.Label>
                    <div className="pick-date bank-pick-date">
                    <DatePicker
                    disabled={updateDetail} 
                      className='form-control w-100'
                      selected={bankFields.expeditionDate}
                      onChange={date => handleExpDateChange(date)}
                      selectsStart
                      startDate={bankFields.expeditionDate}
                      maxDate={new Date()}
                      // endDate={endDate}
                      placeholderText={t("expedition_date")}
                    // dateFormat="YYYY-MM-DD"
                      />
                      <i class="fa fa-calendar" aria-hidden="true"></i>
                      </div>
                      <span className="errors">{t(bankFieldsErrs.expeditionDate)}</span>
                      </Form.Group>



                  
                    <Form.Group className="mb-3 position-relative">
                      <Form.Label>{t("birthDate")} </Form.Label>
            
                    <div className="pick-date bank-pick-date">
                    <DatePicker
                    disabled={updateDetail} 
                    className='form-control'
                      selected={bankFields.birthDate}
                      onChange={date => handleDobDateChange(date)}
                      selectsStart
                      // startDate={startDate}
                      // endDate={endDate}
                      placeholderText={t("birthDate")}
                      // maxDate={}
                      maxDate={new Date("01-01-2008")}
                    // dateFormat="YYYY-MM-DD"
                      />
                      <i class="fa fa-calendar" aria-hidden="true"></i>
                      </div>
                      <span className="errors">{t(bankFieldsErrs.birthDate)}</span>

                      </Form.Group>
                    {/* <Form.Group className="mb-4" controlId="formBasicCheckbox">
                      
                    <Form.Check type="checkbox"   checked={updatechecked} disabled={updateDetail} label={
                      <>
                        <span>{t("agreement")} <a className="terms-condition" href="https://stripe.com/connect-account/legal/full" target="_blank">{t("stripe_connected")}</a>.</span>
                      </>
                    }
                      onChange={(e) => {
                        setbankFields((prevObj) => {
                          return {
                            ...prevObj,
                            terms_and_conditions: e.target.checked
                          };
                        });
                        setupdatechecked(e.target.checked)
                      }}
                    />
                     <span className="errors">{t(bankFieldsErrs.terms_and_conditions)}</span>
                    </Form.Group> */}
                   {!updateDetail?
                    <Button className="green-btn" onClick={handleSubmit}>{t("save")} </Button>:null }

                  </Form>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};
export default BankDetails;
